<template>
  <v-row>
    <v-col>
      <v-text-field
        label="Search By Id"
        color="grey"
        hide-details
        style="max-width: 300px"
        v-model="nid"
        outlined
        type="number"
        dense
      >
      </v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        label="Search By Name"
        color="grey"
        hide-details
        style="max-width: 300px"
        v-model="searchName"
        outlined
        dense
      >
      </v-text-field>
    </v-col>
    <v-col>
      <v-btn class="mt-n2" elevation="1" fab small @click="search">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    searchDelinquent: {
      type: Function,
      default: () => []
    }
  },
  data () {
    return {
      searchName: null,
      searchType: true,
      nid: null
    }
  },
  methods: {
    search () {
      this.searchDelinquent({ nid: this.nid, name: this.searchName })
      // if (this.currentRoute === 'Delinquent') {
      //   this.searchDelinquent()
      // }
    }
    // searchDelinquent () {
    //   // if (this.nid === null) {
    //   this.$delinquentService
    //     .fetchAllTransfersByNidOrName({ nid: this.nid, name: this.searchName })
    //     .then((response) => {
    //       if (response.numberOfElements > 0) {
    //         this.setDelinquents(response.content)
    //         this.nid = null
    //         this.searchName = null
    //       } else {
    //         this.setSnackbar(true)
    //         this.setColor('orange')
    //         this.setText('Not found')
    //         this.nid = null
    //       }
    //     })
    //   // } else {
    //   //   this.setSnackbar(true)
    //   //   this.setColor('orange')
    //   //   this.setText('NID should be atleast 13 digits')
    //   // }
    // }
  }
}
</script>
